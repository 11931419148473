@import "../../FrameworkUI/style.scss";

#header-logo {
  color: $theme-primary;
  font-weight: bold;
  font-size: 22px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: 1rem;

  svg {
    margin: 0 1rem 0 0.5rem;
  }
}

header {
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e2e4;
}


.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
}

.non-sticky-header {
  position: relative;
}

#header-links {
  display: flex;
  flex-direction: row;
  margin-left: 10rem;
  font-size: 16px;
  font-weight: 600;

  a {
    margin: 0 1.5rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #555;
  }

  a:hover {
    color: black;
  }

  .active-header-link {
    color: black;
  }
}

@media screen and (max-width: 850px) {
  #header-links {
    margin-left: 10%;
  }
}

#header-icons {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 0;

  svg {
    margin: 0 0.5rem 0 1rem;
  }

  svg:hover {
    cursor: pointer;
  }
}
