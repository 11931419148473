.text-area {
  box-sizing: border-box;
  min-height: 6rem;
  resize: none;
  overflow: scroll;
  display: inline-block;
  border: none;
  border: 1px solid #d0d2d4;
  background-color: white;
  box-sizing: border-box;
  margin-top: 0;
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  font-size: 14px;
  padding: 6px 6px;
  border-radius: 4px;
}

.text-area:focus {
  outline: none !important;
  border: 1px solid #a0a2a4;
}

.label-text {
  color: #505254;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}
