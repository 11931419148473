.modal-background-filter {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 15;
}

.modal-shell {
  position: fixed;
  background-color: white;
  z-index: 20;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-content {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  height: calc(100% - 3rem);
}

.modal-heading {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.86);
  margin-bottom: 1rem;
}

.modal-sm {
  width: 350px;
  height: 250px;
  left: calc(50% - 175px);
  top: calc(40% - 125px);
}

@media only screen and (max-width: 400px) {
  .modal-sm {
    left: 2%;
    width: 96%;
  }
}

.modal-md {
  width: 500px;
  height: 350px;
  left: calc(50% - 250px);
  top: calc(45% - 175px);
}

@media only screen and (max-width: 550px) {
  .modal-md {
    left: 2%;
    width: 96%;
  }
}

.modal-lg {
  width: 900px;
  height: 700px;
  left: calc(50% - 450px);
  top: calc(50% - 350px);
}

@media only screen and (max-width: 950px) {
  .modal-lg {
    left: 2%;
    width: 96%;
  }
}
