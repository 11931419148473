@import '../../../FrameworkUI/style.scss';

.sidebar-link {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.86);
  overflow: hidden;
  font-size: 14px;
}

.sidebar-icon {
  width: 50px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.sidebar-link.active {
  background-color: rgba(0, 0, 0, 0.04);
}

.sidebar-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.heading-link-item {
  margin-bottom: 2rem;
  color: $theme-primary;
  font-weight: bold;
  font-size: 22px;
  align-items: center;
}

.heading-link-item:hover {
  background-color: white;
}

.sidebar-label {
  flex: none;
}
