.company_row {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 1rem;
}

.company_form {
  margin: 0 1rem;
}
