@import '../../../frameworkUI/style.scss';

#job_details_body {
  width: 180px;
  border-right: 1px solid rgb(200, 200, 200);
  padding: 0 1.5rem;
  height: 95%;
  overflow: hidden;
}

.job_title {
  color: black;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 0 16px 0;
}

.icon_xs {
  width: 14px;
  height: 14px;
}

.job_panel_attribute {
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  color: rgb(80, 80, 80);

  svg {
    margin-right: 8px;
  }

  a {
    text-decoration: none;
    color: $theme-alt;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
    
  }
}
