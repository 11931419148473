.icon-xs {
  width: 12px;
  height: 12px;
}

.icon-sm {
  width: 16px;
  height: 16px;
}

.icon-md {
  width: 18px;
  height: 18px;
}

.icon-lg {
  width: 24px;
  height: 24px;
}

.icon-container {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.icon-container:hover {
  cursor: pointer;
  background-color: rgba(100,100,100,.08);
}

.icon-label {
  font-size: 14px;
  padding-top: 12px;
  opacity: 0.8;
}

.icon-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.display-icon {
  padding-right: 8px;
  margin-bottom: 8px;
}

.display-container {
  padding: 1rem;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid rgba(200,200,200, .3)
}
