#main-intro-container {
  background-color: white;
  position: relative;
}

#intro {
  position: relative;
  z-index: 2;
}

#info {
  z-index: 10;
}

.custom-shape-divider-top-1633139961 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 3;
}

.custom-shape-divider-top-1633139961 svg {
  position: relative;
  display: block;
  width: calc(127% + 1.3px);
  height: 59px;
}

.custom-shape-divider-top-1633139961 .shape-fill {
  fill: #ffffff;
}

.main-info-heading {
  padding-top: 6rem;
  margin-top: 0;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.main-info-paragraph {
  width: 72%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  margin: 1rem auto 0 auto;
}

.main-bg-column-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.main-bg-column-item {
  padding-bottom: 1.75rem;
  transition: 2s;
}

.main-bg-column-item.bold {
  // color: #838587;
  color: #c2699e;
}
