#options_bar {
  border-bottom: 1px solid #e0e2e4;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  #main_options {
    display: flex;
    flex-direction: row;

    div {
      margin: 0 1rem;
    }
  }
}

#search_bar {
  margin: 0 8px;
  width: 400px;
}

#profile_icons {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;

  svg {
    padding: 0 0.5rem 0 1rem;
  }

  svg:hover {
    cursor: pointer;
  }
}