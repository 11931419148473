.dropdown {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  height: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  z-index: 1;
  right: 0;
  border-radius: 4px;
  color: black;
  overflow: hidden;
}

.dropdown-content div {
  padding: 6px 8px;
  display: block;
  overflow: hidden;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
