@import '../../../FrameworkUI/style.scss';

.button {
  border: none;
  border-radius: 4px;
  font-weight: 600;
  // box-shadow: 0 1 2 rgba(0,0,0,.2);
}

.button:hover {
  cursor: pointer;
}

.button-primary {
  background-color: $theme-primary;
  color: white;
}

.button-primary:hover {
  background-color: lighten($theme-primary, 10%);
}

.button-secondary {
  background-color: $theme-secondary;
  color: white;
}
.button-secondary:hover {
  background-color: lighten($theme-secondary, 10%);
}

.button-sm {
  padding: 4px 12px;
  font-size: .8rem;
  height: 1.6rem;
}

.button-md {
  padding: 8px 12px;
  min-width: 100px;
  height: 2rem;
}

.button-lg {
  padding: 12px 16px;
  min-width: 100px;
}
