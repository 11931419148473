.text-input {
  height: 2rem;
  border: none;
  border-bottom: 1px solid #d0d2d4;
  background-color: white;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  margin-top: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.text-input:focus {
  outline: none !important;
  border-bottom: 1px solid #a0a2a4;
}

.text-input-lg {
  height: 2.5rem;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.text-input-md {
  height: 1.6rem;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
}

.label-text {
  color: #505254;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}
