#side-menu {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
  height: 100%;
  border-right: 1px solid #e0e2e4;
  overflow: hidden;
  position: relative;
}

.toggle-chevron {
  position: absolute;
  bottom: 1rem;
}

.open {
  width: 180px !important;
}

.closed {
  width: 50px !important;
}

.cls-chev {
  cursor: pointer;
  width: 100%;
}
