@import '../../FrameworkUI/style.scss';

#dashboard-view {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

#dashboard-content {
  height: 100%;
  width: 100%;
  overflow: scroll;
}
