$theme-primary: #0466C8;
$theme-alt: #C2699E;
$theme-secondary: #6A6C6E;
$theme-danger: #E31A05;

.icon-sm {
  width: 16px;
  height: 16px;
}

.icon-md {
  width: 18px;
  height: 18px;
}

.icon-lg {
  width: 32px;
  height: 32px;
}

.icon-md,
.icon-sm {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

$theme-primary-100: #0466c8;
$theme-primary-80: #4482e8;
$theme-primary-60: #6a9fff;



$theme-alt-100: #0466C8;
$theme-alt-80: #5D64C3;
$theme-alt-60: #8363BC;
$theme-alt-40: #9F63B3;
$theme-alt-20: #B365A8;
$theme-alt-0: #C2699E;
