/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #c0c2c4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow: scroll;
  color: rgba(0, 0, 0, 0.8);
}

.autocomplete-items div {
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #e0e2e4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
