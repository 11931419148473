html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 16px;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #f0f4f6;
  // background-color: #F2F6F8;
  background-color: white;
}

#root {
  height: 100%;
}
