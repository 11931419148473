.company-block {
  width: 100%;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f4f6fa;
}

.company-block:hover,
.company-block.active:hover {
  cursor: pointer;
  background-color: #c4c6ca;
}

.company-block.active {
  border-right: 4px solid blue;
  background-color: #e4e6ea;
}
