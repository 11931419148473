@import '../../../FrameworkUI/style.scss';

#kanban-container {
  height: 100vh;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#kanban-options-bar {
  height: 40px;
  margin: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.board-content {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  overflow: hidden;
}

.column-container {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem 0.5rem 0;
}

.add-column-tile {
  margin-top: 3rem;
  width: 270px;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: rgba(242, 244, 246, .8);
  display: flex;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  align-content: center;
  text-align: center;
  color: $theme-alt;
  font-size: 16px;
  font-weight: 700;
}

.add-column-tile:hover {
  cursor: pointer;
  background-color: rgba(242, 244, 246, .6);
}
