.job_tile_container {
  width: 100%;
  height: 140px;
  margin: auto;
  margin-bottom: .75rem;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.job_tile_heading {
  color: black;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 0 2px 12px;
}

.job_tile_body {
  padding: 0.5rem 12px;
  position: relative;
  flex-grow: 1;
}

.job_tile_position {
  font-size: 14px;
  margin-bottom: 4px;
}

.job_tile_location {
  font-size: 14px;
  color: #555;
}

.job_tile_status {
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  bottom: 2px;
  right: 6px;
}

.job_tile_position,
.job_tile_location {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.icon_xs {
  width: 14px;
  height: 14px;
}
