@import '../../../FrameworkUI/style.scss';

.column_container {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem 0.5rem 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.column_header {
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: rgba(80, 80, 80);
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 32px;
  align-items: center;
  flex-shrink: 0;

  .column_drag_icon {
    margin: 0 0.2rem 0 0.8rem;
    height: 16px;
    width: 20px;
    opacity: 0.2;
  }

  .column_header_text {
    flex-grow: 1;
  }

  .column_options_wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column_options_icon {
    margin: 0 0.8rem 0 0.2rem;
    height: 16px;
    width: 20px;
    opacity: 0.2;
  }

  .column_drag_icon:hover,
  .dropdown_container:hover .column_options_icon {
    opacity: 0.8;
  }

  .column_options_wrapper:hover {
    cursor: pointer;
  }
}

.column_body {
  width: 270px;
  background-color: white;
  flex-grow: 1;
  margin-top: 0.4rem;
  border-radius: 4px;
  background-color: #f2f4f6;
  box-shadow: 0 1 2 rgba(0, 0, 0, 0.2);
  padding: 0.8rem 0.6rem;
  overflow: scroll;
}

.danger_text {
  color: $theme-danger;
}

.column_add_job_tile {
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  align-content: center;
  text-align: center;
  color: $theme-alt;
  font-size: 16px;
  font-weight: 700;
  opacity: .86;
}

.column_add_job_tile:hover {
  cursor: pointer;  
  opacity: 1;
}
