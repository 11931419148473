.bg_filter {
  background-color: rgb(0, 0, 0);
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  // left: 0;
  z-index: 9;
  transition: opacity .3s;

  &_open {
    left: 0;
    opacity: 0.2;
  }
}

.filter-enter {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  transition: 1s;
}
.filter-enter-active {
  opacity: 1;
  // transition: opacity 500ms ease-in;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
  transition: 1s;
}
.filter-exit {
  opacity: 1;
}
.filter-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.content_body {
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
  background-color: white;
  border-left: 1px solid #e0e2e4;
  overflow: hidden;
  z-index: 10;
}

.content_body.open {
  width: 1000px;
}

.fixed_elements {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.panel_body {
  position: absolute; /* effectively fixed */
  top: 0;
  right: 0;
  bottom: 0;
  width:100%;
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
  overflow: hidden;
}
